import AuthFeature from "./features/Auth"
import DashboardFeature from "./features/Dashboard"
import FightFeature from "./features/Fight"
import MenuFeature from "./features/Menu"
import ModuleFeature from "./features/Module"
import PermissionFeature from "./features/Permission"
import UserFeature from "./features/User"

export const mainRoutes = [
    {path: '/', Component: DashboardFeature},
    {path: '/auth/*', Component: AuthFeature},
    {path: '/user/*', Component: UserFeature},
    {path: '/module/*', Component: ModuleFeature},
    {path: '/menu/*', Component: MenuFeature},
    {path: '/permissions/*', Component: PermissionFeature},
    {path: '/fight/*', Component: FightFeature},
]