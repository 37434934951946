import { CButton, CFormInput, CInputGroup, CInputGroupText } from '@coreui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
export default function SetWeight({ setWeight, party, weight }) {
    console.log('WEIGHT', weight)
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { isSubmitting },
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            weight: weight
        },
        resolver: yupResolver(validator),
    })
    useEffect(()=>{
        console.log('fuck')
        setValue('weight', weight)
    }, [weight])
    const submit = (values) => {
        setWeight(party, values.weight)
    }
    return (
        <CInputGroup  size='sm'>
            <CFormInput placeholder={`Số gram ${party}`} {...register('weight')} />
            <CInputGroupText>gram</CInputGroupText>
            <CButton type="button" color="success" variant="outline" onClick={handleSubmit(submit)}>Lưu</CButton>
        </CInputGroup>
    )
}
const validator = Yup.object({
    rate: Yup.number().max(100).min(0)
})