import axiosClient from "../../../api/axiosClient"

const hallApi = {
    get(params){
        return axiosClient.get('/hall', {
            params: params
        })
    },
    detail(id){
        return axiosClient.get('/hall/'+id)
    },
    create(){

    },
    update(){

    },
    delete(){

    },
    startEvent(hallId){
        return axiosClient.post('hall/startEvent/'+hallId)
    },
    startBet(hallId){
        return axiosClient.post('hall/startBet/'+hallId)
    },
    endBet(fightId){
        return axiosClient.post('hall/endBet/'+fightId)
    },
    cancelBet(fightId){
        return axiosClient.post('hall/cancelBet/'+fightId)
    },
    setWinner(fightId, party){
        return axiosClient.post('hall/setWinner/'+fightId, {party})
    },
    setRate(fightId, data){
        return axiosClient.post('hall/setRate/'+fightId, data)
    },
    setWeight(fightId, data){
        return axiosClient.post('hall/setWeight/'+fightId, data)
    },
    currentBet(fightId){
        return axiosClient.get('hall/getBets/'+fightId)
    }
}

export default hallApi