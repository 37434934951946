import { CButton, CCard, CCardBody, CCardHeader, CCardText, CCardTitle } from "@coreui/react";
import React from "react";

export default function Dashboard() {
  return (
    <CCard>
      <CCardHeader>Your Trading board</CCardHeader>
      <CCardBody>
        <CCardTitle>Special title treatment</CCardTitle>
        <CCardText>
          With supporting text below as a natural lead-in to additional content.
        </CCardText>
        <CButton href="#">Go somewhere</CButton>
      </CCardBody>
    </CCard>
  );
}
