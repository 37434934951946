import { cilBell, cilCommentSquare, cilCreditCard, cilEnvelopeOpen, cilFile, cilLockLocked, cilSettings, cilTask, cilUser } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CAvatar, CBadge, CContainer, CDropdown, CDropdownDivider, CDropdownHeader, CDropdownItem, CDropdownMenu, CDropdownToggle, CHeader, CHeaderNav, CHeaderToggler, CLink, CNavItem, CNavLink, CNavTitle, CSidebar, CSidebarBrand, CSidebarHeader, CSidebarNav } from '@coreui/react';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Link, Outlet } from 'react-router-dom';
import { useAuth } from '../../features/Auth/context/AuthContext';
import { useSidebarStore } from '../../store';

export default function DashboardLayout() {
    
    const {sidebarShow, setSidebarShow} = useSidebarStore()
    const auth = useAuth()
    return (
     <div>
      
      <Toaster position='bottom-right'/>
        <CSidebar
          className="border-end"
          position="fixed"
          visible={sidebarShow}
          onVisibleChange={(visible) => {
            setSidebarShow(visible)
          }}
        >
          <CSidebarBrand>HALL68 Manager</CSidebarBrand>
          <CSidebarNav>
            <CNavTitle>MAIN</CNavTitle>
            {auth.user.group_id===1?<>
              <li className='nav-item'><Link to='/agency' className='nav-link'></Link></li>
              <li className='nav-item'><Link to='/user' className='nav-link'>Người chơi</Link></li>
              <li className='nav-item'><Link to='/hall' className='nav-link'>Sảnh</Link></li>
              <li className='nav-item'><Link to='/bet' className='nav-link'>Cược</Link></li>
              </>:''}
            <li className='nav-item'><Link to='/fight' className='nav-link'>Điều hành trận đấu</Link></li>
          </CSidebarNav>
        </CSidebar>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
          <CHeader className="header header-sticky mb-4">
            <CContainer fluid>
              <CSidebarHeader>
                <CHeaderToggler onClick={() => setSidebarShow(!sidebarShow)} />
              </CSidebarHeader>
              <CHeaderNav className="ms-3">
                <CDropdown variant="nav-item">
                  <CDropdownToggle
                    placement="bottom-end"
                    className="py-0"
                    caret={false}
                  >
                    <CAvatar color="primary" textColor="white">AD</CAvatar>

                  </CDropdownToggle>
                  <CDropdownMenu
                    className="pt-0"
                    placement="bottom-end"
                  >
                    <CDropdownHeader className="bg-light fw-semibold py-2">
                      Account
                    </CDropdownHeader>
                    <CDropdownItem href="#">
                      <CIcon
                        icon={cilBell}
                        className="me-2"
                      />
                      Updates
                      <CBadge
                        color="info"
                        className="ms-2"
                      >
                        42
                      </CBadge>
                    </CDropdownItem>
                    
                    <CDropdownDivider />
                    <CDropdownItem onClick={()=>auth.logout()}>
                      <CIcon
                        icon={cilLockLocked}
                        className="me-2"
                      />
                      Đăng xuất
                    </CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </CHeaderNav>
            </CContainer>
          </CHeader>
          <div className="body flex-grow-1 px-3">
            <CContainer lg>
              <Outlet />
            </CContainer>
          </div>
        </div>
     </div>
    );
}
